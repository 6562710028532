import { saveAs } from 'file-saver'
//import XLSX from 'xlsx'
import ExcelJS from'exceljs';

export async function export_json_to_excel({
  multiHeader = [],
  header,
  data,
  filename,
  merges = [],
  dataValidations = [],
  autoWidth = true,
  bookType = 'xlsx',
  sheets = ["Page1"],
  hiddens = [],
  download=true
} = {}) {
  const workbook = new ExcelJS.Workbook();
  filename = filename || 'excel-list';
 
  for (let sheetName of sheets) {
    let rowData = 2;
    let worksheet =  workbook.addWorksheet(sheetName)
    if(header.find(e => e.sheet == sheetName).hidden){
      worksheet.hidden = true;
    }
    
    let dataSheet = data.find(e => e.sheet == sheetName).data
    dataSheet = [...dataSheet]
    dataSheet.unshift(header.find(e => e.sheet == sheetName).data);
    if(multiHeader.length > 0){
      if(multiHeader.find(e => e.sheet == sheetName)){
        rowData =rowData+multiHeader.find(e => e.sheet == sheetName).data.length;
        for (let i = multiHeader.find(e => e.sheet == sheetName).data.length - 1; i > -1; i--) {
          let col = multiHeader.find(e => e.sheet == sheetName).data[i];
          dataSheet.unshift(col)
        }
      }
    }
    worksheet.addRows(dataSheet)
    if(merges.length > 0){
      if (merges.find(e => e.sheet == sheetName)) {
      if (merges.find(e => e.sheet == sheetName).data.length > 0) {
          merges.find(e => e.sheet == sheetName).data.forEach(item => {
            worksheet.mergeCells(item.s.r,item.s.c,item.e.r,item.e.c);
          })
        }
      }
    }
    if(autoWidth){
      worksheet.columns.forEach(column => {
        let lengths = column.values.map(v => v.toString().length);
        let maxLength = Math.max(...lengths.filter(v => typeof v === 'number'))+2;
        column.width = maxLength;
      });
    }
    if(dataValidations.length > 0){
      if(dataValidations.find(e => e.sheet == sheetName)){
        if (dataValidations.find(e => e.sheet == sheetName).data.length > 0) {
          let validaciones = dataValidations.find(e => e.sheet == sheetName).data;
            worksheet.eachRow({ includeEmpty: true }, function(row, rowNumber) {
              row.eachCell({ includeEmpty: true },function(cell, colNumber) {
                if( rowNumber >= rowData && validaciones.some(e=> e.columnNum == colNumber)){
                  let validacion = validaciones.find(e=> e.columnNum == colNumber);
                  cell.dataValidation = {
                    type: 'list',
                    allowBlank: validacion.allowBlank,
                    formulae: validacion.lista
                  };
                }
              });
            });
          }
        }
      }
      if(hiddens.length > 0){
        if(hiddens.find(e => e.sheet == sheetName)){
          if (hiddens.find(e => e.sheet == sheetName).columns.length > 0) {
            let hideColumns = hiddens.find(e => e.sheet == sheetName).columns;
             for (let colNumber of hideColumns) {
              let col = worksheet.getColumn(colNumber);
              col.hidden = true;
             }
          }
            if (hiddens.find(e => e.sheet == sheetName).rows.length > 0) {
              let hideRows = hiddens.find(e => e.sheet == sheetName).rows;
               for (let rowNumber of hideRows) {
                let row = worksheet.getRow(rowNumber);
                row.hidden = true;
               }
            }
          }
        }
    }
  return await new Promise((resolve) => {
    workbook.xlsx.writeBuffer().then((data) => {
      if(download){
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${filename}.${bookType}`);
        resolve(true);
      }else{
        resolve(data);
      }
    });
  });
}